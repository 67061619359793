import React, { Component } from 'react'
import Wrapper from './Wrapper'

class MainPage extends Component {
    render() {
        return (
            <div className="home-page">
                <Wrapper/>
            </div>
        )
    }
}

export default MainPage;